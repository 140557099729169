<template>
  <div class="frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div
      v-if="!pageNotFound"
      class="page-content p4a__activity-content"
    >
      <header class="p4a__header">
        <b-container>
          <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <router-link
              :to="'/appointments'"
              :class="isMobile() ? 'mx-auto' : ''"
            >
              <img :src="isMobile() ? logoMobileImg : logoImg">
            </router-link>
            <!-- /Brand logo-->
          </b-row>
        </b-container>
      </header>

      <div class="breadcrumb-block breadcrumb__mobile">
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb class="p4a__breadcrumb">
                <b-breadcrumb-item>
                  <router-link :to="'/appointments'">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                        fill="white"
                      />
                      <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="1"
                        width="13"
                        height="13"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <rect
                          x="0.5"
                          y="0.5"
                          width="14"
                          height="14"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </router-link>
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  class="p4a__breadcrumb-item"
                  active
                >
                  {{ activity.typeName }} Booking
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div
        ref="stepper_block"
        class="step-block"
        :class="{'stepper_fixed': scrolled}"
        @scroll="stepperHandleScroll"
      >
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb>
                <b-breadcrumb-item
                  :class="{ 'active': phase == 'customerDetail', 'icon-active': getActivePhase('customerDetail') }"
                  @click="changePhaseFromBreadcrumb('customerDetail')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="UserIcon" />
                    </div>
                    <div
                      v-if="customFieldsLength"
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Details of Applicant 申请人资料</h4>
                      <p v-if="isMobile()">
                        Next: Add-on services 附加服务
                      </p>
                      <p v-else>
                        Enter your details
                      </p>
                    </div>
                    <div
                      v-else
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Details of Applicant 申请人资料</h4>
                      <p v-if="isMobile()">
                        Summary 预约摘要
                      </p>
                      <p v-else>
                        Enter your details
                      </p>
                    </div>
                  </div>

                  <div v-if="isMobile()">
                    <div
                      v-if="customFieldsLength"
                      class="circle-wrap one-third"
                    >
                      <div class="circle">
                        <div class="mask full">
                          <div class="fill" />
                        </div>
                        <div class="mask half">
                          <div class="fill" />
                        </div>
                        <div class="inside-circle">
                          1 of 3
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="circle-wrap one-third"
                    >
                      <div class="circle">
                        <div class="mask full">
                          <div class="fill" />
                        </div>
                        <div class="mask half">
                          <div class="fill" />
                        </div>
                        <div class="inside-circle">
                          1 of 2
                        </div>
                      </div>
                    </div>
                  </div>
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-if="customFieldsLength"
                  :class="{ 'active': phase == 'packageDetail', 'icon-active': getActivePhase('packageDetail') }"
                  :disabled="!enableNextPhaseCustomer"
                  @click="changePhaseFromBreadcrumb('packageDetail')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="BoxIcon" />
                    </div>
                    <div class="item-text p4a__activity-item_text">
                      <h4>Add-on services 附加服务</h4>
                      <p />
                      <p v-if="isMobile()">
                        Next: Summary 概括
                      </p>
                      <p v-else>
                        Choose add-on services
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="isMobile()"
                    class="circle-wrap two-third"
                  >
                    <div class="circle">
                      <div class="mask full">
                        <div class="fill" />
                      </div>
                      <div class="mask half">
                        <div class="fill" />
                      </div>
                      <div class="inside-circle">
                        2 of 3
                      </div>
                    </div>
                  </div>
                </b-breadcrumb-item>

                <b-breadcrumb-item
                  :class="{ 'active': phase == 'summaryPhase', 'icon-active': getActivePhase('summaryPhase') }"
                  :disabled="!enableNextPhaseCustomFields"
                  @click="changePhaseFromBreadcrumb('summaryPhase')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="ListIcon" />
                    </div>
                    <div class="item-text p4a__activity-item_text">
                      <h4>Summary 预约摘要</h4>
                      <p v-if="!isMobile()">
                        View your booking summary
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="isMobile()"
                    class="circle-wrap"
                  >
                    <div class="circle">
                      <div class="mask full">
                        <div class="fill" />
                      </div>
                      <div class="mask half">
                        <div class="fill" />
                      </div>
                      <div
                        v-if="customFieldsLength"
                        class="inside-circle"
                      >
                        3 of 3
                      </div>
                      <div
                        v-else
                        class="inside-circle"
                      >
                        2 of 2
                      </div>
                    </div>
                  </div>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="events event-register main-content">
        <b-form @submit.prevent="submitFullForm">
          <validation-observer
            ref="frontActivityBookingCreateForm"
            #default="{invalid}"
          >
            <b-container>
              <b-row class="row8">
                <b-col
                  v-if="phase == 'customerDetail'"
                  lg="8"
                  class="top-info-left"
                >
                  <b-card>
                    <b-card-text>
                      <h3 class="p4a__input-specifier__title">
                        Details of Applicant 申请人资料
                      </h3>
                      <p class="p4a__input-specifier__sub-title">
                        We will use your contact details to keep you updated regarding further details about the event registered.
                      </p>

                      <b-row>
                        <b-col
                          v-if="verifiedType != 'agent'"
                          lg="10"
                        >
                          <div class="form-row form-group">
                            <label class="col-md-5 col-form-label p4a__text-bold-gray">Applicant Name *</label>
                            <div class="col">
                              <validation-provider
                                #default="{ }"
                                name="Applicant Name"
                                vid="h-first-name"
                                rules="required"
                              >
                                <b-form-input
                                  id="h-first-name"
                                  v-model="customerName"
                                  class="p4a__activity-input"
                                  placeholder="Full Name"
                                  :state="(customerNameValidation) ? false : null"
                                  name="customerName"
                                  :disabled="customer.Name ? true : false"
                                  @input="validateCustomerName"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <small
                                  v-if="customerNameValidation"
                                  class="text-danger"
                                >
                                  {{ customerNameError }}
                                </small>
                              </validation-provider>
                            </div>
                          </div>
                        </b-col>
                        <b-col
                          v-else
                          lg="10"
                        >
                          <div
                            class="form-row form-group search-bar p4a"
                          >
                            <label class="col-md-5 col-form-label p4a__text-bold-gray">Applicant Name *</label>
                            <div class="col">
                              <b-input-group :class="!showCustomerCrossIcon ? 'p4a__control-border-right' : 'p4a__control-border-right-off'">
                                <b-form-input
                                  id="search_name"
                                  v-model="searchInput"
                                  label="Applicant Name"
                                  name="name"
                                  class="p4a__activity-input"
                                  placeholder="Select from list or enter a name"
                                  autocomplete="off"
                                  tabindex="0"
                                  @keyup="submitSearchInput()"
                                  @focusout="resetSearchResult"
                                />
                                <b-input-group-append
                                  v-if="showCustomerCrossIcon"
                                  is-text
                                >
                                  <feather-icon
                                    icon="XIcon"
                                    class="cursor-pointer p4a__text-base-light-color"
                                    size="18"
                                    data-toggle
                                    @click="clearSearchInput"
                                  />
                                </b-input-group-append>
                              </b-input-group>

                              <div v-show="showSearchResult">
                                <div
                                  class="search-results mt-50"
                                >
                                  <div v-if="foundCustomers.length">
                                    <b-list-group
                                      flush
                                    >
                                      <span class="found-text-block p4a-color">Customers found</span>
                                      <b-list-group-item
                                        v-for="(cus, sIndex) in foundCustomers"
                                        :key="sIndex"
                                        class="cursor-pointer"
                                        @click="addSearchInputItem(cus)"
                                      >
                                        <div
                                          class="search-item-text-block"
                                        >
                                          <h6>
                                            {{ cus.Name }}
                                          </h6>
                                          <br>
                                          <h6>
                                            {{ cus.Per_Tel_No_Hp }} / {{ cus.NRIC }} {{ cus.Per_Email ? `/ ${cus.Per_Email}` : '' }}
                                          </h6>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </div>
                                </div>
                              </div>
                              <b-card
                                v-show="searchResultNotFound"
                                no-body
                                class="mt-50 mb-0"
                              >
                                <div class="p4a__result-not-found-block">
                                  <h5 class="mb-0">
                                    No result found. Continue typing to enter the applicant name.
                                  </h5>
                                </div>
                              </b-card>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="!isPhoneHidden"
                        id="phone_block"
                      >
                        <b-col lg="10">
                          <div class="form-row form-group">
                            <label class="col-md-5 col-form-label p4a__text-bold-gray">Mobile Number *</label>
                            <div class="col">
                              <validation-provider
                                #default="{ }"
                                name="Applicant Mobile"
                                vid="h-phone-number"
                              >
                                <cleave
                                  v-if="verifiedType == 'agent'"
                                  id="h-phone-number"
                                  v-model="customerContact"
                                  class="form-control p4a__activity-input"
                                  :raw="false"
                                  :options="options.prefix"
                                  :disabled="existingCustomer"
                                  @input="validateCustomerContact"
                                />
                                <b-form-input
                                  v-else
                                  id="h-phone-number"
                                  v-model="customerContact"
                                  class="p4a__activity-input"
                                  placeholder="+65 8 Digits Phone Number"
                                  :state="(customerContactValidation) ? false : null"
                                  name="customerContact"
                                  disabled
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <small
                                  v-if="customerContactValidation"
                                  class="text-danger"
                                >
                                  {{ customerContactError }}
                                </small>
                              </validation-provider>
                            </div>
                          </div>
                        </b-col>

                        <b-col
                          v-if="verifiedType == 'agent'"
                          lg="10"
                        >
                          <b-row>
                            <b-col md="5" />
                            <b-col
                              md="7"
                              class="use_email"
                            >
                              <b-button
                                variant="link"
                                class="p4a__text-base-color phone-email-button text-left"
                                @click="isPhoneHidden = true; isEmailHidden = false; customerContact = ''"
                              >
                                Not from Singapore? Use email instead
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="!isEmailHidden"
                        id="email_block"
                      >
                        <b-col lg="10">
                          <div class="form-row form-group">
                            <label class="col-md-5 col-form-label p4a__text-bold-gray">Email Address*</label>
                            <div class="col">
                              <validation-provider
                                #default="{ }"
                                name="Applicant Email"
                                vid="h-customer-email"
                                :rules="isEmailHidden === false ? 'required' : ''"
                              >
                                <b-form-input
                                  id="h-customer-email"
                                  v-model="customerEmail"
                                  class="p4a__activity-input"
                                  placeholder="E-mail Address"
                                  :state="(customerEmailValidation) ? false : null"
                                  name="customerEmail"
                                  :disabled="verifiedType == 'agent' ? false : true"
                                  @input="validateCustomerEmail"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <small
                                  v-if="customerEmailValidation"
                                  class="text-danger"
                                >
                                  {{ customerEmailError }}
                                </small>
                              </validation-provider>
                            </div>
                          </div>
                        </b-col>

                        <b-col
                          v-if="verifiedType == 'agent'"
                          lg="10"
                        >
                          <b-row>
                            <b-col md="5" />
                            <b-col
                              md="7"
                              class="use_email"
                            >
                              <b-button
                                variant="link"
                                class="p4a__text-base-color phone-email-button text-left"
                                @click="isPhoneHidden = false; isEmailHidden = true; customerEmail = ''"
                              >
                                Use mobile number instead
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr class="p4a__hr-base-border">
                      <h3 class="p4a__input-specifier__title">
                        Your Agent Details 代理人资料
                      </h3>
                      <p />
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis.</p> -->

                      <b-row>
                        <b-col lg="10">
                          <b-form-group
                            label="Agent Name"
                            label-class="p4a__text-bold-gray"
                            label-for="h-agent-name"
                            label-cols-md="5"
                          >
                            <b-form-input
                              id="h-agent-name"
                              v-model="agentName"
                              class="p4a__activity-input"
                              placeholder="Agent Name"
                              :disabled="verifiedType == 'agent' ? true : false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col lg="10">
                          <b-form-group
                            label="Agent Code"
                            label-class="p4a__text-bold-gray"
                            label-for="h-agent-code"
                            label-cols-md="5"
                          >
                            <b-form-input
                              id="h-agent-code"
                              v-model="agentCode"
                              class="p4a__activity-input"
                              type="text"
                              placeholder="Agent Code"
                              :disabled="verifiedType == 'agent' ? true : false"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr class="p4a__hr-base-border">
                      <b-row>
                        <b-col cols="12">
                          <h3 class="p4a__input-specifier__title">
                            Remarks / Notes 备注
                          </h3>
                          <b-form-textarea
                            id="textarea-default"
                            v-model="remarks"
                            class="p4a__activity-input"
                            placeholder="E.g. Please contact me during daytime."
                            rows="3"
                          />
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>

                  <b-card>
                    <b-card-text>
                      <h3 class="p4a__input-specifier__title">
                        Timeslots Booking {{ activity.timeSlotTitleSG }}
                      </h3>
                      <p />

                      <b-alert
                        v-if="existingBookingWarning"
                        variant="danger"
                        show
                      >
                        <div class="alert-body">
                          <span>You have an existing booking for this timeslot. As a result, your new booking may be subject to further approval by one of our Duty Officers. Choose a different timeslot to avoid this.</span>
                        </div>
                      </b-alert>

                      <b-row class="time__slots">
                        <b-col lg="12">
                          <b-form-group
                            label="Choose a Date"
                            label-class="p4a__text-bold-gray"
                            label-for="h-start"
                            label-cols-md="4"
                          >
                            <b-input-group class="p4a__flat-pickr-wrapper">
                              <flat-pickr
                                id="h-event-start"
                                ref="datePicker"
                                v-model="start"
                                class="form-control flat-pickr-group"
                                :config="flatPickrConfig"
                                @on-change="validateStartDate"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="cursor-pointer p4a__text-base-light-color"
                                  data-toggle
                                  size="18"
                                />
                              </b-input-group-append>
                            </b-input-group>

                            <small
                              v-if="startValidation"
                              class="text-danger"
                            >
                              {{ startError }}
                            </small>
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          class="mb-margin-0"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Start Time"
                            vid="h-start-time"
                            rules="required"
                          >
                            <b-form-group
                              label="Choose a Timeslot"
                              label-class="p4a__text-bold-gray"
                              label-for="h-start-time"
                              label-cols-md="4"
                            >
                              <b-row class="row5">
                                <b-col
                                  v-for="(option, index) in timeSlots"
                                  :key="index"
                                  md="4"
                                  cols="6"
                                >
                                  <b-form-radio
                                    v-model="startTime"
                                    name="timeslot"
                                    :value="option.timeValue"
                                    class="mb-50 p4a__radio-input"
                                    :disabled="option.status !== 'enabled'"
                                    :state="(errors.length > 0) ? false : null"
                                    @change="validateStartTime"
                                  >
                                    <span>{{ option.time }}</span>
                                  </b-form-radio>
                                </b-col>
                              </b-row>
                            </b-form-group>

                            <small
                              v-if="startTimeValidation"
                              class="text-danger"
                            >
                              {{ startTimeError }}
                            </small>
                            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-col>

                <b-col
                  v-if="phase == 'packageDetail'"
                  lg="8"
                  class="top-info-left"
                >
                  <b-card>
                    <b-row
                      v-for="(opt, key) in activity.customFields"
                      :key="key"
                    >
                      <b-col
                        v-if="opt.type == 'file' || opt.type == 'title-and-text'"
                        lg="12"
                      >
                        <div
                          v-if="opt.type == 'file'"
                          class="details_img"
                        >
                          <img :src="opt.file">
                        </div>

                        <div v-if="opt.type == 'title-and-text'">
                          <h3 class="p4a__input-specifier__title">
                            {{ opt.label }}
                          </h3>
                          <p class="p4a__input-specifier__sub-title">
                            {{ opt.description }}
                          </p>
                        </div>
                      </b-col>

                      <b-col
                        v-if="!['file', 'title-and-text'].includes(opt.type)"
                        lg="12"
                      >
                        <b-form-group
                          v-if="opt.type == 'short-answer'"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                          label-class="p4a__input-specifier__title"
                          label-cols-md="12"
                        >
                          <div class="p4a__input-specifier__sub-title mb-50">
                            {{ opt.description }}
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-input
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              :placeholder="opt.label"
                              :state="(errors.length > 0) ? false : null"
                              @input="enableNextPhaseCustomFieldsDetail"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'long-answer'"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                          label-class="p4a__input-specifier__title"
                          label-cols-md="12"
                        >
                          <div class="p4a__input-specifier__sub-title mb-50">
                            {{ opt.description }}
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-textarea
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              trim
                              :placeholder="opt.label"
                              :state="(errors.length > 0) ? false : null"
                              @input="enableNextPhaseCustomFieldsDetail"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                          label-cols-md="12"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                          label-class="p4a__input-specifier__title"
                        >
                          <div class="p4a__input-specifier__sub-title mb-50">
                            {{ opt.description }}
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >

                            <b-form-checkbox-group
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              buttons
                              button-variant="outline-primary"
                              class="spaceBetweenRadio display-block-checkbox"
                              :state="(errors.length > 0) ? false : null"
                              @change="enableNextPhaseCustomFieldsDetail"
                            >
                              <b-form-checkbox
                                v-for="(option, kindex) in opt.extraOptions"
                                :key="kindex"
                                :value="option.key"
                                class="mb-50 p4a__site-booking-checkbox"
                              >
                                <feather-icon
                                  v-if="customFields[key].value.includes(option.key)"
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">{{ option.key }}</span>
                              </b-form-checkbox>
                            </b-form-checkbox-group>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                          label-class="p4a__input-specifier__title"
                          label-cols-md="12"
                          class="mb-margin-0"
                        >
                          <div class="p4a__input-specifier__sub-title mb-50">
                            {{ opt.description }}
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <!-- <b-form-radio-group
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              button-variant="outline-primary"
                              buttons
                              class="spaceBetweenRadio"
                              :state="(errors.length > 0) ? false : null"
                              @change="enableNextPhaseCustomFieldsDetail"
                            >
                              <b-form-radio
                                v-for="(option, kindex) in opt.extraOptions"
                                :key="kindex"
                                :value="option.key"
                                class="mb-50"
                              >
                                <feather-icon
                                  v-if="customFields[key].value === option.key"
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">{{ option.key }}</span>
                              </b-form-radio>
                            </b-form-radio-group> -->

                            <b-form-checkbox-group
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              buttons
                              button-variant="outline-primary"
                              class="spaceBetweenRadio display-block-checkbox"
                              :state="(errors.length > 0) ? false : null"
                              @input="makeCheckboxToRadio(key)"
                            >
                              <b-form-checkbox
                                v-for="(option, kindex) in opt.extraOptions"
                                :key="kindex"
                                :value="option.key"
                                class="mb-50 p4a__site-booking-checkbox"
                              >
                                <feather-icon
                                  v-if="customFields[key].value.includes(option.key)"
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">{{ option.key }}</span>
                              </b-form-checkbox>
                            </b-form-checkbox-group>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'single-select'"
                          label-cols-md="12"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                        >
                          <div class="p4a__input-specifier__sub-title mb-50">
                            {{ opt.description }}
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >

                            <!-- <b-form-radio-group
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              button-variant="outline-primary"
                              buttons
                              class="spaceBetweenRadio"
                              :state="(errors.length > 0) ? false : null"
                              @change="enableNextPhaseCustomFieldsDetail"
                            >
                              <b-form-radio
                                v-for="(option, kindex) in requiredOptions"
                                :key="kindex"
                                :value="option.value"
                              >
                                <feather-icon
                                  v-if="customFields[key].value === option.value"
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">{{ option.text }}</span>
                              </b-form-radio>
                            </b-form-radio-group> -->
                            <b-form-checkbox-group
                              :id="'event-custom-field-' + key"
                              v-model="customFields[key].value"
                              buttons
                              button-variant="outline-primary"
                              class="spaceBetweenRadio display-block-checkbox"
                              :state="(errors.length > 0) ? false : null"
                              @input="makeCheckboxToRadio(key)"
                            >
                              <b-form-checkbox
                                v-for="(option, kindex) in requiredOptions"
                                :key="kindex"
                                :value="option.value"
                                class="mb-50 p4a__site-booking-checkbox"
                              >
                                <feather-icon
                                  v-if="customFields[key].value.includes(option.value)"
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">{{ option.text }}</span>
                              </b-form-checkbox>
                            </b-form-checkbox-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <b-col
                  v-if="phase == 'summaryPhase'"
                  lg="8"
                  class="top-info-left"
                >
                  <b-card>
                    <button
                      class="btn btn-link p4a__back-btn"
                      type="button"
                      @click="phase = 'customerDetail'"
                    >
                      Back to editing
                    </button>
                    <b-card-text class="applicant__details">
                      <h3 class="p4a__input-specifier__title">
                        Details of Applicant 申请人资料
                      </h3>
                      <p class="p4a__input-specifier__sub-title">
                        We will use your contact details to keep you updated regarding further details about the event registered.
                      </p>

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Applicant Name</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ customerName || '-' }}</p>
                        </b-col>
                      </b-row>

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Mobile Number</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ customerContact || '-' }}</p>
                        </b-col>
                      </b-row>

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Email</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ customerEmail || '-' }}</p>
                        </b-col>
                      </b-row>
                      <hr class="p4a__hr-base-border">
                      <h3 class="p4a__input-specifier__title">
                        Your Agent Details 代理人资料
                      </h3>
                      <p />
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis.</p> -->

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Agent Name</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ agentName || '-' }}</p>
                        </b-col>
                      </b-row>

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Agent Code</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ agentCode || '-' }}</p>
                        </b-col>
                      </b-row>

                      <b-row class="sum_list p4a__input-value-preview">
                        <b-col md="3">
                          <strong>Agent Contact</strong>
                        </b-col>
                        <b-col md="9">
                          <p>{{ agentContact || '-' }}</p>
                        </b-col>
                      </b-row>

                      <hr class="p4a__hr-base-border">
                      <b-row>
                        <b-col cols="12">
                          <h3 class="p4a__input-specifier__title">
                            Remarks / Notes 备注
                          </h3>
                          <p class="p4a_input-value-text line-breaks-textarea">
                            {{ remarks || '-' }}
                          </p>
                        </b-col>
                      </b-row>

                      <hr class="p4a__hr-base-border">
                      <b-row>
                        <b-col cols="12">
                          <h3 class="p4a__input-specifier__title">
                            Timeslots Booking 选择拜祭时段
                          </h3>
                          <p class="p4a_input-value-text">
                            {{ start }}, {{ startTime ? dateFormatFromTimeStringWithoutTimezone(startTime) : '' }}
                          </p>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>

                  <b-card
                    v-if="customFieldsLength"
                    class="package-details"
                  >
                    <button
                      class="btn btn-link p4a__back-btn"
                      type="button"
                      @click="phase = 'packageDetail'"
                    >
                      Back to editing
                    </button>
                    <h3 class="p4a__input-specifier__title">
                      Add-on Services 附加服务
                    </h3>
                    <b-row
                      v-for="(opt, key) in customFields"
                      :key="key"
                      class="sum_list p4a__input-value-preview"
                    >
                      <b-col
                        v-if="opt.field != 'title-and-text' && opt.field != 'file'"
                        md="3"
                      >
                        <strong>{{ opt.key }}</strong>
                      </b-col>
                      <b-col
                        md="9"
                      >
                        <div v-if="Array.isArray(opt.value) && opt.value.length > 0">
                          <p
                            v-for="(option, index) in opt.value"
                            :key="index"
                          >
                            {{ option || '-' }} <span v-if="index + 1 != opt.value.length">, </span>
                          </p>
                        </div>
                        <div v-else>
                          <p class="text-capitalize">
                            {{ Array.isArray(opt.value) ? ('-') : ((opt.field != 'title-and-text' && opt.field != 'file') ? (opt.value || '-') : '') }}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <b-col
                  ref="sticky_sidebar"
                  lg="4"
                  class="top-info-right"
                >
                  <vue-sticky-sidebar
                    class="sidebar"
                    container-selector=".container"
                    inner-wrapper-selector=".sidebar__inner"
                  >
                    <b-card
                      no-body
                      class="sticky_sidebar"
                    >
                      <div class="event-info p4a__event-info-wrapper">
                        <h3 class="p4a__input-specifier__title">
                          {{ activity.typeName }}
                        </h3>
                        <ul class="event_info p4a__event-info">
                          <li class="event_date">
                            <span v-if="start">
                              {{ start || '-' }}, {{ startTime ? dateFormatFromTimeStringWithoutTimezone(startTime) : '-' }}
                              <br>
                              {{ dateFormatWithoutTimezoneMandarin(start) || '-' }}, {{ startTime ? dateFormatFromTimeStringWithoutTimezoneMandarin(startTime) : '-' }}
                            </span>
                            <span v-else>-<br>-</span>
                          </li>
                          <li class="event_location">
                            {{ activity.location }}<br>{{ activity.locationSG }}
                          </li>
                        </ul>
                      </div>
                      <div v-if="isMobile()">
                        <div
                          v-show="phase === 'customerDetail'"
                          class="fixed_action_block"
                        >
                          <button
                            class="back__button p4a__text-base-color"
                            @click="$router.back()"
                          >
                            Exit
                          </button>
                          <button
                            v-show="phase === 'customerDetail'"
                            type="button"
                            class="btn p4a__common-btn w-100"
                            block
                            :disabled="!enableNextPhaseCustomer"
                            @click="changePhase"
                          >
                            Proceed to next step<br>进行下一步
                          </button>
                        </div>

                        <div
                          v-show="phase === 'packageDetail'"
                          class="fixed_action_block"
                        >
                          <button
                            class="back__button"
                            @click="changePhaseFromBreadcrumb('customerDetail')"
                          >
                            Back
                          </button>
                          <button
                            v-show="phase == 'packageDetail'"
                            type="button"
                            class="btn p4a__common-btn w-100"
                            block
                            :disabled="!enableNextPhaseCustomFields"
                            @click="changePhase"
                          >
                            Proceed to next step<br>进行下一步
                          </button>
                        </div>
                      </div>

                      <div class="package__info">
                        <div
                          v-show="phase === 'customerDetail'"
                          class="button__block"
                        >
                          <button
                            v-if="isMobile()"
                            class="back__button p4a__text-base-color"
                            @click="$router.back()"
                          >
                            Exit
                          </button>
                          <button
                            v-show="phase === 'customerDetail'"
                            type="button"
                            class="btn p4a__common-btn w-100"
                            block
                            :disabled="!enableNextPhaseCustomer"
                            @click="changePhase"
                          >
                            Proceed to next step<br>进行下一步
                          </button>
                        </div>
                        <div
                          v-show="phase == 'packageDetail'"
                          class="button__block"
                        >
                          <button
                            v-if="isMobile()"
                            class="back__button p4a__text-base-color"
                            @click="changePhaseFromBreadcrumb('customerDetail')"
                          >
                            Back
                          </button>
                          <button
                            v-show="phase == 'packageDetail'"
                            type="button"
                            class="btn p4a__common-btn w-100"
                            block
                            :disabled="!enableNextPhaseCustomFields"
                            @click="changePhase"
                          >
                            Proceed to next step<br>进行下一步
                          </button>
                        </div>

                        <div v-show="phase === 'summaryPhase'">
                          <validation-provider
                            #default="{ errors }"
                            name="Agreement"
                            vid="h-agreement"
                            rules="required"
                          >
                            <b-form-checkbox
                              v-model="secondAgree"
                              value="accepted"
                              unchecked-value=""
                              class="p4a__checkbox-bg"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <span class="p4a__summary-checkbox-text">
                                By registering, you agree to follow the PDPA agreement. (If you do not agree, we cannot collect your personal data and accept your registration)
                                <br>
                                我已阅读并同意遵守上述个人资料保护令协议。（若您不同意，我们将无法收集您的个人资料，并接受您的注册）
                              </span>
                            </b-form-checkbox>
                          </validation-provider>
                          <div
                            v-if="isMobile()"
                            class="fixed_action_block"
                          >
                            <button
                              v-if="customFieldsLength"
                              class="back__button p4a__text-base-color"
                              @click="changePhaseFromBreadcrumb('packageDetail')"
                            >
                              Back
                            </button>
                            <button
                              v-else
                              class="back__button p4a__text-base-color"
                              @click="changePhaseFromBreadcrumb('customerDetail')"
                            >
                              Back
                            </button>

                            <button
                              type="button"
                              class="btn p4a__common-btn w-100"
                              block
                              :disabled="invalid"
                              @click="submitFullForm"
                            >
                              Submit Registration Form<br>提交表格
                            </button>
                          </div>
                          <div
                            v-if="customFieldsLength"
                            class="button__block"
                          >
                            <button
                              v-if="isMobile()"
                              class="back__button p4a__text-base-color"
                              @click="changePhaseFromBreadcrumb('packageDetail')"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              class="btn p4a__common-btn w-100"
                              block
                              :disabled="invalid"
                              @click="submitFullForm"
                            >
                              Submit Registration Form<br>提交表格
                            </button>
                          </div>
                          <div
                            v-else
                            class="button__block"
                          >
                            <button
                              v-if="isMobile()"
                              class="back__button p4a__text-base-color"
                              @click="changePhaseFromBreadcrumb('customerDetail')"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              class="btn p4a__common-btn w-100"
                              block
                              :disabled="invalid"
                              @click="submitFullForm"
                            >
                              Submit Registration Form<br>提交表格
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </vue-sticky-sidebar>
                </b-col>
              </b-row>
            </b-container>
          </validation-observer>
        </b-form>
      </div>

      <b-modal
        id="modal-1"
        size="lg"
        title=""
        centered
        no-stacking
        hide-footer
        header-class="front-modal"
      >
        <h3>Confirmation Required</h3>
        <p v-if="isPhoneHidden">
          We will now send a one time password to <strong>applicant’s email {{ customerEmail }}</strong> to confirm your registration.<br>
          为了确认成功报名注册，我们将会寄出一次性密码至<strong>申请人邮箱 {{ customerEmail }}</strong>
        </p>
        <p v-else>
          We will now send a one time password to <strong>applicant’s mobile (65){{ customerContact }}</strong> to confirm your registration.<br>
          为了确认成功报名注册，我们将会寄出一次性密码至<strong>申请人邮箱 (65){{ customerContact }}</strong>
        </p>
        <b-button
          variant="primary"
          @click="sendConfirmationOTP('customer')"
        >
          Send OTP to {{ customerEmail ? customerEmail : customerContact }} 一次性密码
        </b-button>
        <div class="clear" />
        <b-button
          v-b-modal.modal-2
          variant="link"
        >
          Use agent’s contact instead
        </b-button>
      </b-modal>

      <!-- modal -->
      <b-modal
        id="modal-2"
        size="lg"
        title=""
        centered
        no-stacking
        hide-footer
        header-class="front-modal"
      >
        <h3>Confirmation Required</h3>
        <p>We will now send a one time password to the <strong>agent’s mobile below</strong> to confirm your registration.<br>为了确认成功报名注册，我们将会寄出一次性密码至以下所填写的代理人号码。</p>
        <b-row>
          <b-col md="6">
            <b-form-input
              id="h-agent-phone-number"
              v-model="agentContact"
              placeholder="(65) 8 Digits Phone Number"
            />
            <small
              v-if="agentContactValidation"
              class="text-danger"
            >
              {{ agentContactError }}
            </small>
          </b-col>
        </b-row>
        <b-button
          v-b-modal.modal-3
          variant="primary"
          :disabled="agentContact.length !== 8"
          @click="sendConfirmationOTP('agent')"
        >
          Send OTP to (65) {{ agentContact }} 一次性密码
        </b-button>
        <div class="clear" />
        <b-button
          v-b-modal.modal-1
          variant="link"
        >
          Use applicant’s mobile number instead
        </b-button>
      </b-modal>

      <!-- modal -->
      <b-modal
        id="modal-3"
        size="lg"
        title=""
        centered
        no-stacking
        hide-footer
        header-class="front-modal"
      >
        <h3>One Time Password</h3>
        <p>Enter your one time password send to {{ otpSentToPhone }}. 请输入寄去 {{ otpSentToPhone }}的一次性密码。</p>
        <b-row>
          <b-col md="6">
            <b-form-input
              id="h-phone-number"
              v-model="enteredOTP"
              placeholder="Enter OTP"
            />

            <small
              v-if="enteredOTPValidation"
              class="text-danger"
            >
              {{ enteredOTPError }}
            </small>
          </b-col>
          <b-col
            md="12"
            class="otp_sending"
          >
            <p v-if="timeCountDown > 0">
              Resend OTP in {{ timeCountDown }} seconds
            </p>
            <p
              v-else
              class="clickableText"
              @click="resendConfirmationOTP"
            >
              Resend OTP
            </p>
          </b-col>
        </b-row>

        <b-button
          :disabled="enteredOTP.length !== 6"
          variant="primary"
          @click="submitFullForm"
        >
          Submit 提交
        </b-button>
      </b-modal>

      <footer />
    </div>

    <not-found-error
      v-if="pageNotFound"
    />

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* eslint-disable global-require */
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BRow, BContainer, BCol, BBreadcrumb, BBreadcrumbItem, BInputGroup,
  BCard, BCardText, BFormGroup, BFormInput, BButton, BFormTextarea, BListGroup, BListGroupItem,
  BInputGroupAppend, BFormRadio, BAlert, BForm, BFormCheckbox, BModal,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'

import NotFoundError from '../pages/error/NotFoundError.vue'
import Loader from './Loader.vue'

export default {
  components: {
    BForm,
    BRow,
    BContainer,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormTextarea,
    BInputGroupAppend,
    BFormRadio,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BModal,
    // BFormRadioGroup,
    BFormCheckboxGroup,

    'vue-sticky-sidebar': vueStickySidebar,
    BAlert,
    Cleave,
    flatPickr,
    Loader,
    NotFoundError,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      limitPosition: 195,
      scrolled: false,
      showCustomerCrossIcon: false,
      lastPosition: 0,
      bottomActionBlock: 0,
      phase: 'customerDetail',
      activity: {},
      pack: {},
      timeSlots: [],
      enabledDates: [],
      start: '',
      startValidation: false,
      startError: 'Valid date is required',
      startTime: '',
      startTimeValidation: false,
      startTimeError: 'Valid time is required',
      customerName: '',
      customerNameValidation: false,
      customerNameError: 'Valid name is required',
      customerNRIC: '',
      customerContact: '',
      customerContactValidation: false,
      customerContactError: 'The Mobile Number field is required',
      customerEmail: '',
      customerEmailValidation: false,
      customerEmailError: 'Valid email is required',
      agentContactError: 'Valid mobile is required',
      agentContactValidation: false,
      remarks: '',
      title: '',
      agentName: '',
      agentCode: '',
      agentContact: '',
      otpSentToPhone: '',
      enteredOTP: '',
      enteredOTPError: 'Valid otp is required',
      enteredOTPValidation: false,
      customFields: [],
      timeSlotDuration: '',
      secondAgree: '',
      existingBookingWarning: false,
      pageNotFound: false,
      enableNextPhaseCustomer: false,
      enableNextPhaseCustomFields: false,
      timeCountDown: 0,
      hasAnotherBooking: false,
      logoImg: require('@/assets/images/frontend/logo-orange-yellow-svg.svg'),
      logoMobileImg: require('@/assets/images/frontend/logo-orange-yellow-mobile.svg'),
      modalIcon: require('@/assets/images/frontend/modal_icon.svg'),
      isPhoneHidden: false,
      isEmailHidden: true,
      customFieldsLength: false,
      verifiedType: '',
      searchInput: '',
      customer: {},
      agentCustomers: [],
      foundCustomers: [],
      searchResultNotFound: false,
      showSearchResult: false,
      agent: {},
      existingCustomer: false,
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', enable: [], disable: [], minDate: 'today', disableMobile: true,
      },
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],

      required,
    }
  },
  beforeMount() {
    this.$http.get(`front-end/activity/${this.$route.params.id}/show`)
      .then(response => {
        this.activity = response.data.data || {}
        // this.start = this.dateFormat(this.event.startDate)
        const enable = []
        this.activity.timeSlots.forEach(element => {
          const timeObj = {}
          timeObj.from = this.dateFormat(element.startDate)
          timeObj.to = this.dateFormat(element.endDate)

          enable.push(timeObj)
        })

        this.flatPickrConfig.enable = enable

        // let disable = []

        // if (response.data.blockOutDate) {
        //   disable = [
        //     {
        //       from: this.dateFormat(response.data.blockOutDate.startDate),
        //       to: this.dateFormat(response.data.blockOutDate.endDate),
        //     },
        //   ]
        // }

        // this.flatPickrConfig.disable = disable

        let fieldCount = 0
        if (this.activity.customFields.length) {
          const newArray = []
          this.activity.customFields.forEach(arrayItem => {
            const newObj = {}
            if (!['file', 'title-and-text'].includes(arrayItem.type)) {
              // eslint-disable-next-line no-plusplus
              fieldCount++
            }
            newObj.key = arrayItem.label
            newObj.field = arrayItem.type
            newObj.required = arrayItem.required
            if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
              newObj.value = []
            } else {
              newObj.value = ''
            }
            newArray.push(newObj)
          })

          this.customFields = newArray
        }

        if (fieldCount > 0) {
          this.customFieldsLength = true
        }

        this.pageNotFound = false
        this.enableNextPhaseCustomFieldsDetail()
      })
      .catch(error => {
        this.pageNotFound = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    window.addEventListener('scroll', this.stepperHandleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.stepperHandleScroll)
  },
  mounted() {
    this.calcPosOfBox()
    this.verifiedType = localStorage.getItem('verifiedCustomerType')
    if (this.verifiedType === 'agent') {
      this.agent = JSON.parse(localStorage.getItem('bookingAgent'))
      this.agentCustomers = JSON.parse(localStorage.getItem('agentCustomers'))
      this.agentName = this.agent.Agent_Name
      this.agentCode = this.agent.Agent_Code
      this.agentContact = this.modifyContact(this.agent.TelNo_P)
    } else {
      this.customer = localStorage.getItem('verifiedCustomer') ? JSON.parse(localStorage.getItem('verifiedCustomer')) : {}
      this.customerName = this.customer.Name || ''
      this.customerNRIC = this.customer.NRIC || ''
      const contactType = localStorage.getItem('customerVerifiedContactType')
      const contact = localStorage.getItem('customerVerifiedContact')
      if (contactType === 'phone') {
        this.customerContact = this.modifyContact(contact)

        if (this.customerContact.length === 12) {
          this.customerContactValidation = false
          this.enableNextPhaseCustomerDetail()
        } else {
          this.customerContactValidation = true
          this.enableNextPhaseCustomer = false
        }
      } else {
        this.customerEmail = contact
        this.isPhoneHidden = true
        this.isEmailHidden = false
        this.enableNextPhaseCustomerDetail()
      }
      // this.checkForCustomerSchedule()
    }
  },

  methods: {
    async calcPosOfBox() {
      await this.$nextTick()
      this.bottomActionBlock = this.$refs.sticky_sidebar.offsetTop
      this.limitPosition = this.$refs.stepper_block.getBoundingClientRect().y
    },
    submitSearchInput() {
      // this.searchResultNotFound = false
      // this.showSearchResult = false
      // this.foundCustomers = []
      const found = this.agentCustomers.filter(item => item.Name.toLowerCase().includes(this.searchInput.toLowerCase()))
      if (found && found.length) {
        this.searchResultNotFound = false
        this.showSearchResult = true
        this.foundCustomers = found
      } else {
        // if (this.searchResultNotFound === true) {
        //   this.searchResultNotFound = false
        // } else {
        //   this.searchResultNotFound = true
        // }
        this.searchResultNotFound = true
        this.showSearchResult = false
        this.foundCustomers = []
        this.customerName = this.searchInput
        this.enableNextPhaseCustomerDetail()
      }
    },
    resetSearchResult() {
      setTimeout(() => {
        this.searchResultNotFound = false
        this.showSearchResult = false
      }, 500)
    },

    clearSearchInput() {
      this.searchInput = ''
      this.customerName = ''
      this.customerContact = '+65 '
      this.customerNRIC = ''
      this.customerEmail = ''
      this.existingCustomer = false
      this.showCustomerCrossIcon = false
      this.searchResultNotFound = false
      this.showSearchResult = false
    },

    addSearchInputItem(customer) {
      if (customer.Per_Tel_No_Hp) {
        this.existingCustomer = true
      }
      this.showCustomerCrossIcon = true
      this.searchInput = customer.Name
      this.customerName = customer.Name || ''
      this.customerContact = customer.Per_Tel_No_Hp || '+65 '
      this.customerNRIC = customer.NRIC
      this.customerEmail = customer.Per_Email
      this.foundCustomers = []
      this.showSearchResult = false
      this.searchResultNotFound = false
      this.enableNextPhaseCustomerDetail()
      this.checkForCustomerSchedule()
    },
    async handleFocusOut() {
      await this.$nextTick()
      this.showSearchResult = false
      this.searchResultNotFound = false
    },
    stepperHandleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true
        document.body.style.paddingTop = '60px'
      }
      if (this.limitPosition > window.scrollY && this.lastPosition > window.scrollY) {
        this.scrolled = false
        document.body.style.paddingTop = '0px'
      }

      if (this.lastPosition + window.outerHeight > this.bottomActionBlock + 100) {
        document.body.classList.add('hideStickyBar')
      } else {
        document.body.classList.remove('hideStickyBar')
      }
      this.lastPosition = window.scrollY
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-5', '')
    },
    getTimeFromDB() {
      if (this.start) {
        this.$http.post('front-end/activity/bookings/date-and-time-availability', { date: this.start, activity: this.$route.params.id })
          .then(response => {
            this.timeSlots = response.data.hours
            this.timeSlotDuration = response.data.timeSlotDuration
            if (!this.timeSlots.length) {
              this.startTimeError = 'No Time Slot Available for This Date'
              this.startTimeValidation = true
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },

    checkForCustomerSchedule() {
      if (this.startTime && (this.customerContact.length === 12 || this.validateEmail(this.customerEmail))) {
        let customer = ''
        if (this.isEmailHidden === true) {
          customer = this.customerContact
        } else {
          customer = this.customerEmail
        }
        this.$http
          .post('front-end/bookings/customer-schedule-availability', { date: this.start, time: this.startTime, customer })
          .then(response => {
            if (response.data.exists === true) {
              this.existingBookingWarning = true
              this.hasAnotherBooking = true
            } else {
              this.existingBookingWarning = false
              this.hasAnotherBooking = false
            }
          })
          .catch(() => {
            // this.existingBookingWarning = true
          })
      }
    },

    changePhase() {
      if (this.phase === 'customerDetail') {
        if (this.customFieldsLength) {
          this.phase = 'packageDetail'
        } else {
          this.phase = 'summaryPhase'
        }
      } else if (this.phase === 'packageDetail') {
        this.phase = 'summaryPhase'
      }

      window.scrollTo(0, 0)
      this.calcPosOfBox()
    },

    getActivePhase(phase) {
      if (phase === 'customerDetail') {
        if (this.phase === 'customerDetail' || this.phase === 'packageDetail' || this.phase === 'summaryPhase') {
          return true
        }
      } else if (phase === 'packageDetail') {
        if (this.phase === 'packageDetail' || this.phase === 'summaryPhase') {
          return true
        }
      } else if (phase === 'summaryPhase') {
        if (this.phase === 'summaryPhase') {
          return true
        }
      }

      return false
    },

    changePhaseFromBreadcrumb(phase) {
      this.phase = phase
      window.scrollTo(0, 0)
      this.calcPosOfBox()
    },

    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },

    validateCustomerName() {
      this.customerNameValidation = false
      this.enableNextPhaseCustomerDetail()
    },

    validateCustomerContact() {
      if (this.customerContact.length > 4 && this.customerContact.length <= 12) {
        this.customerContact = this.modifyContact(this.customerContact)

        if (this.customerContact.length === 12) {
          this.customerContactValidation = false
          this.enableNextPhaseCustomerDetail()
          this.checkForCustomerSchedule()
        } else {
          this.customerContactValidation = true
          this.enableNextPhaseCustomer = false
        }
      } else if (this.customerContact.length === 4) {
        this.customerContactValidation = false
        this.enableNextPhaseCustomer = false
      } else {
        this.customerContactValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateCustomerEmail() {
      if (this.validateEmail(this.customerEmail)) {
        this.customerEmailValidation = false
        this.enableNextPhaseCustomerDetail()
        this.checkForCustomerSchedule()
      } else {
        this.customerEmailValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateStartTime() {
      if (this.startTime !== '') {
        this.startTimeValidation = false
        this.enableNextPhaseCustomerDetail()
        this.checkForCustomerSchedule()
      } else {
        this.startTimeValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateStartDate() {
      this.startTime = ''
      this.startTimeValidation = false
      if (this.start !== '') {
        this.startValidation = false
        this.enableNextPhaseCustomerDetail()
        this.getTimeFromDB()
      } else {
        this.startValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    enableNextPhaseCustomerDetail() {
      if (this.customerName.length !== 0 && (this.customerContact.length === 11 || this.customerContact.length === 12 || this.validateEmail(this.customerEmail)) && this.start !== '' && this.startTime !== '') {
        this.enableNextPhaseCustomer = true
      } else {
        this.enableNextPhaseCustomer = false
      }
    },

    enableNextPhaseCustomFieldsDetail() {
      this.generateBookingTitle()
      this.customFields.every(obj => {
        if (!['file', 'title-and-text'].includes(obj.field)) {
          if (obj.required === 'yes' && obj.value.length === 0) {
            this.enableNextPhaseCustomFields = false
            return false
          }
        }
        this.enableNextPhaseCustomFields = true
        return true
      })
    },

    makeCheckboxToRadio(key) {
      const lastSelected = this.customFields[key].value.slice(-1)[0]
      if (lastSelected) {
        this.customFields[key].value = this.customFields[key].value.filter(code => code === lastSelected)
      }

      this.enableNextPhaseCustomFieldsDetail()
    },

    generateBookingTitle() {
      let title = ''
      this.activity.customFields.forEach((arrayItem, key) => {
        if (arrayItem.displayInCalendar === 'yes' && this.customFields[key].value !== '') {
          const val = this.customFields[key].value
          if (Array.isArray(val)) {
            val.forEach(arrVal => {
              const calVal = arrayItem.extraOptions.find(o => o.key === arrVal)
              title = `${title}${arrayItem.label} ${calVal.value} `
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (arrayItem.extraOptions.length) {
              const calVal = arrayItem.extraOptions.find(o => o.key === val)
              if (calVal) {
                title = `${title}${arrayItem.label} ${calVal.value} `
              } else {
                title = `${title}${arrayItem.label} ${val} `
              }
            } else {
              title = `${title}${arrayItem.label} ${val} `
            }
          }
        }
      })
      this.title = title
    },

    sendConfirmationOTP(type) {
      if (type === 'agent') {
        this.otpSentToPhone = this.agentContact
      } else {
        this.otpSentToPhone = this.customerContact ? this.customerContact : this.customerEmail
      }

      this.$http
        .post('front-end/bookings/send-otp', { otpSentToPhone: this.otpSentToPhone, customerName: this.customerName })
        .then(response => {
          this.timeCountDown = 60
          this.$root.$emit('bv::show::modal', 'modal-3', '')
          this.countDownTimer()
          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    resendConfirmationOTP() {
      this.$http
        .post('front-end/bookings/send-otp', { otpSentToPhone: this.otpSentToPhone, customerName: this.customerName })
        .then(response => {
          this.timeCountDown = 60
          this.countDownTimer()

          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    countDownTimer() {
      if (this.timeCountDown > 0) {
        setTimeout(() => {
          this.timeCountDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    submitFullForm() {
      this.$refs.frontActivityBookingCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('customerName', this.customerName)
          formData.append('customerNRIC', this.customerNRIC)
          formData.append('customerContact', this.customerContact)
          formData.append('customerEmail', this.customerEmail)
          formData.append('activityType', this.$route.params.id)
          formData.append('startDate', this.start)
          formData.append('startTime', this.startTime)
          formData.append('timeSlotDuration', this.timeSlotDuration)
          formData.append('remarks', this.remarks)
          formData.append('title', this.title)
          formData.append('hasAnotherBooking', this.hasAnotherBooking)
          formData.append('agentID', (this.agent && this.agent._id) ? this.agent._id : '')
          formData.append('agentName', this.agentName)
          formData.append('agentCode', this.agentCode)
          formData.append('agentContact', this.agentContact)
          // formData.append('otpSentToPhone', this.otpSentToPhone)
          // formData.append('enteredOTP', this.enteredOTP)
          formData.append('customFields', JSON.stringify(this.customFields))

          this.$http.post('front-end/bookings/store/activity', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  html: `<h3 class="p4a__success-swal-title">Successfully Registered!</h3><p class="p4a__success-swal-subtitle">You will be receiving a confirmation message to be sent to ${this.customerEmail || this.customerContact} shortly.
                  您将很快收到一封确认邮件，该邮件将发送至 ${this.customerEmail || this.customerContact}</p>`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/frontend/success_icon.svg'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Ok, got it 知道了',
                  customClass: {
                    confirmButton: 'btn p4a__success-swal-btn',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointments-front-end' })
                      document.body.style.paddingTop = '0px'
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'customerEmail') {
                    this.customerEmailError = validationError.msg
                    this.customerEmailValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'startDate') {
                    this.startError = validationError.msg
                    this.startValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'startTime') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'enteredOTP') {
                    this.enteredOTPError = validationError.msg
                    this.enteredOTPValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.enteredOTPError = error.data.message
                this.enteredOTPValidation = true
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .clickableText {
    cursor: pointer;
    color: #104D9D;
  }
  .frontend{
    .page-content{
        background: #F5F0DF !important;
        background-size: 100% auto;
        .activities{
           &:before{
             background: unset;
           }
           &:after{
             background: unset;
           }
        }
    }
 }
</style>
